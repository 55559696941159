export const createSpan = ({ innerElement, color, margin, fontWeight }) => {
  const span = document.createElement('span');
  span.innerHTML = innerElement;
  span.style.color = color;
  span.style.margin = margin;
  span.style.fontWeight = fontWeight ? fontWeight : 'normal';
  return span;
};

export const createForemanSpan = ({ dataId, innerElement, color, margin, fontWeight, fontSize }) => {
  const span = createSpan({ innerElement, color, margin, fontWeight });
  if (fontSize) {
    span.style.fontSize = fontSize;
  }
  span.style.width = '15px';
  span.setAttribute('data-id', dataId);
  span.classList.add('foreman-wrapper-icon');
  return span;
};
